/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AppTypes {
    Mediawiki = 'mediawiki',
    Wordpress = 'wordpress',
    Postgresql = 'postgresql',
    Grav = 'grav',
}

export interface App {
    appId: string
    type: {
        id: AppTypes
        name: string
        version: string
    }
    detail: DetailGrav | DetailMediawiki | DetailPostgresql | DetailWordpress
}

export interface AppType {
    id: string
    name: string
    versions: string[]
}

export interface Error {
    detail: string
    key: string
}

export interface DetailGrav {
    url: string
}

export interface DetailMediawiki {
    url: string
    installation: {
        databasetype: string
        databasehost: string
        databasename: string
        databaseuser: string
        databasepassword: string
    }
}

export interface DetailPostgresql {
    host: string
    port: string
    adminerurl: string
    dbuser: string
    dbpassword: string
    dbdatabase: string
}

export interface DetailWordpress {
    phpmyadminurl: string
    url: string
    db: {
        user: string
        password: string
        name: string
    }
}

export interface Status {
    counter: number
    ts: string
    info: string
}

export interface TransactionStatus {
    tId: string
    done: boolean
    status?: Status[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat
    /** request body */
    body?: unknown
    /** base url */
    baseUrl?: string
    /** request cancellation token */
    cancelToken?: CancelToken
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void
    customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D
    error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = 'https://webbe-dev.nomodo.io'
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private abortControllers = new Map<CancelToken, AbortController>()
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams)

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    }

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig)
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key)
        return `${encodedKey}=${encodeURIComponent(
            typeof value === 'number' ? value : `${value}`
        )}`
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key])
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key]
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {}
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        )
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&')
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery)
        return queryString ? `?${queryString}` : ''
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key]
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                )
                return formData
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    }

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken)
            if (abortController) {
                return abortController.signal
            }
            return void 0
        }

        const abortController = new AbortController()
        this.abortControllers.set(cancelToken, abortController)
        return abortController.signal
    }

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken)

        if (abortController) {
            abortController.abort()
            this.abortControllers.delete(cancelToken)
        }
    }

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const queryString = query && this.toQueryString(query)
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json]
        const responseFormat = format || requestParams.format

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${
                queryString ? `?${queryString}` : ''
            }`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {}),
                },
                signal: cancelToken
                    ? this.createAbortSignal(cancelToken)
                    : requestParams.signal,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body),
            }
        ).then(async (response) => {
            const r = response as HttpResponse<T, E>
            r.data = null as unknown as T
            r.error = null as unknown as E

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data
                          } else {
                              r.error = data
                          }
                          return r
                      })
                      .catch((e) => {
                          r.error = e
                          return r
                      })

            if (cancelToken) {
                this.abortControllers.delete(cancelToken)
            }

            return data
        })
    }
}

/**
 * @title nomodo.io WEB API
 * @version 1.0.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @baseUrl https://webbe-dev.nomodo.io
 * @contact <info@nomodo.io>
 */
export class Api<
    SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
    apps = {
        /**
         * @description Creates a new app by data passed in the request body. In the response, it returns type and version.
         *
         * @tags apps
         * @name CreateNewApp
         * @summary Create a new app
         * @request POST:/apps
         * @response `200` `App` Success
         * @response `400` `Error` Invalid payload
         * @response `401` `Error` Authorization failed
         */
        createNewApp: (
            data: {
                /** transactionId */
                tId?: string
                type?: AppTypes
                /** Controls the version of the app that you want to create */
                version?: string
                /** Captcha google token */
                captcha?: string
            },
            params: RequestParams = {}
        ) =>
            this.request<App, Error>({
                path: `/apps`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Retrieves all available properties of an app with a given ID.
         *
         * @tags apps
         * @name GetAppById
         * @summary Get app details
         * @request GET:/apps/{appId}
         * @response `200` `App` Success
         * @response `400` `Error` Invalid payload
         * @response `401` `Error` Authorization failed
         * @response `403` `Error` Permission denied
         * @response `404` `Error` Not found
         */
        getAppById: (appId: string, params: RequestParams = {}) =>
            this.request<App, Error>({
                path: `/apps/${appId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
 * @description delete by id
 *
 * @tags apps
 * @name DeleteById
 * @summary Delete by id
 * @request DELETE:/apps/{appId}
 * @response `200` `{
    appId: string,

}` Success
 * @response `400` `Error` Invalid payload
 * @response `401` `Error` Authorization failed
 * @response `403` `Error` Permission denied
 * @response `404` `Error` Not found
 */
        deleteById: (appId: string, params: RequestParams = {}) =>
            this.request<
                {
                    appId: string
                },
                Error
            >({
                path: `/apps/${appId}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),
    }
    transactionId = {
        /**
 * No description
 *
 * @tags transaction
 * @name GetNewId
 * @summary Get transactionId for create new app
 * @request GET:/transactionId
 * @response `200` `{
  \** transactionId *\
    tId: string,
  \** processUrl *\
    processUrl: string,

}` Success
 * @response `400` `Error` Invalid payload
 */
        getNewId: (params: RequestParams = {}) =>
            this.request<
                {
                    /** transactionId */
                    tId: string
                    /** processUrl */
                    processUrl: string
                },
                Error
            >({
                path: `/transactionId`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * @description Get app status
         *
         * @tags transaction
         * @name GetStatus
         * @summary Get app status
         * @request GET:/transactionId/{transactionId}
         * @response `200` `TransactionStatus` Success
         * @response `400` `Error` Invalid payload
         */
        getStatus: (transactionId: string, params: RequestParams = {}) =>
            this.request<TransactionStatus, Error>({
                path: `/transactionId/${transactionId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    apptypes = {
        /**
         * @description Retrieves info about the app type
         *
         * @tags appTypes
         * @name GetTypeById
         * @summary Get available type by id
         * @request GET:/apptypes/{typeId}
         * @response `200` `AppType` Success
         * @response `400` `Error` Invalid payload
         * @response `401` `Error` Authorization failed
         */
        getTypeById: (typeId: string, params: RequestParams = {}) =>
            this.request<AppType, Error>({
                path: `/apptypes/${typeId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    emails = {
        /**
 * @description Submits the user feedback and sends it to the email
 *
 * @tags emails
 * @name AppInterest
 * @summary Submits the user feedback
 * @request POST:/emails/app-interest
 * @response `200` `{
  \** appId *\
    id: string,
    email: string,

}` Success
 * @response `208` `{
  \** appId *\
    id: string,
    email: string,

}` Already in DB
 * @response `400` `Error` Invalid payload
 */
        appInterest: (
            data: {
                /** Captcha google token */
                captcha: string
                email: string
                note: string
                /** appId */
                appid: string
                component: string
                timezone: string
                lang: string
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /** appId */
                    id: string
                    email: string
                },
                Error
            >({
                path: `/emails/app-interest`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * @description Submits the user feedback and sends it to the email
 *
 * @tags emails
 * @name Feedback
 * @summary Submits the user feedback
 * @request POST:/emails/feedback
 * @response `200` `{
    email: string,

}` Success
 * @response `400` `Error` Invalid payload
 */
        feedback: (
            data: {
                /** Captcha google token */
                captcha: string
                email: string
                subject: string
                note: string
                slug: string
                component: string
                timezone: string
                lang: string
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    email: string
                },
                Error
            >({
                path: `/emails/feedback`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * @description Submits user to the newsletter
 *
 * @tags emails
 * @name Newsletter
 * @summary Submits user to the newsletter
 * @request POST:/emails/newsletter
 * @response `200` `{
    email: string,

}` Success
 * @response `208` `{
    email: string,

}` Already in DB
 * @response `400` `Error` Invalid payload
 */
        newsletter: (
            data: {
                /** Captcha google token */
                captcha: string
                email: string
                slug: string
                component: string
                timezone: string
                lang: string
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    email: string
                },
                Error
            >({
                path: `/emails/newsletter`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
 * @description Suggest new app
 *
 * @tags emails
 * @name SuggestApp
 * @summary Suggest new app
 * @request POST:/emails/suggest-app
 * @response `200` `{
    email: string,

}` Success
 * @response `400` `Error` Invalid payload
 */
        suggestApp: (
            data: {
                /** Captcha google token */
                captcha: string
                email?: string
                note?: string
                name: string
                url: string
                slug: string
                component: string
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    email: string
                },
                Error
            >({
                path: `/emails/suggest-app`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    upload = {
        /**
 * @description Upload config
 *
 * @tags upload
 * @name Config
 * @summary Upload config
 * @request POST:/upload/{typeId}/config
 * @response `200` `{
  \** appId *\
    id: string,

}` Success
 * @response `400` `Error` Invalid payload
 */
        config: (
            typeId: AppTypes,
            data: {
                /** appId */
                appId?: string
                /**
                 * upload file
                 * @format binary
                 */
                file?: File
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /** appId */
                    id: string
                },
                Error
            >({
                path: `/upload/${typeId}/config`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),
    }
}
