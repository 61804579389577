<script lang="ts">
    let classNames = ''
    let forEl = ''
    export { classNames as class }
    export { forEl as for }
</script>

<label class="label {classNames} " for={forEl} {...$$restProps}>
    <slot />
</label>

<style global lang="postcss">
    .label {
        display: block;
    }
</style>
