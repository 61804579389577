<script lang="ts">
    import { concatClasses } from '../utils'

    let className = ''
    export { className as class }
    export let invalid = false
    export let valid = false

    $: classes = concatClasses([
        'feedback',
        valid ? 'valid' : '',
        invalid ? 'invalid' : '',
        className,
    ])
</script>

<div class={classes} {...$$restProps}>
    <slot />
</div>

<style global lang="postcss">
    .feedback {
        margin-top: theme('spacing.1');
        font-size: theme('fontSize.sm');
    }

    .feedback.valid {
        color: theme('colors.green.800');
    }

    .feedback.invalid {
        color: theme('colors.red.800');
    }
</style>
