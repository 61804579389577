<script lang="ts">
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let disabled = false
    export let id: string
    export let name = ''
    export let valid = false
    export let invalid = false
    export let placeholder = ''
    export let readonly = false
    export let required = false
    export let type: 'email' | 'text' | 'password' | 'url' | 'hidden' = 'text'
    export let value = ''

    $: classes = concatClasses([
        'text-field',
        valid ? 'valid' : '',
        invalid ? 'invalid' : '',
        classNames,
    ])
</script>

{#if type === 'text'}
    <input
        {id}
        name={name || undefined}
        class={classes}
        {disabled}
        placeholder={placeholder || undefined}
        {readonly}
        {required}
        type="text"
        on:blur
        on:change
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        bind:value
        {...$$restProps}
    />
{:else if type === 'email'}
    <input
        {id}
        name={name || undefined}
        class={classes}
        {disabled}
        placeholder={placeholder || undefined}
        {readonly}
        {required}
        type="email"
        on:blur
        on:change
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        bind:value
        {...$$restProps}
    />
{:else if type === 'password'}
    <input
        {id}
        name={name || undefined}
        class={classes}
        {disabled}
        placeholder={placeholder || undefined}
        {readonly}
        {required}
        type="password"
        on:blur
        on:change
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        bind:value
        {...$$restProps}
    />
{:else if type === 'url'}
    <input
        {id}
        name={name || undefined}
        class={classes}
        {disabled}
        placeholder={placeholder || undefined}
        {readonly}
        {required}
        type="url"
        on:blur
        on:change
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        bind:value
        {...$$restProps}
    />
{:else if type === 'hidden'}
    <input
        {id}
        name={name || undefined}
        class={classes}
        {disabled}
        placeholder={placeholder || undefined}
        {readonly}
        {required}
        type="hidden"
        on:blur
        on:change
        on:focus
        on:input
        on:keydown
        on:keypress
        on:keyup
        bind:value
        {...$$restProps}
    />
{/if}

<style global lang="postcss">
    .text-field {
        appearance: none;
        display: block;
        width: 100%;
        padding: theme('spacing.3') theme('spacing.4');
        font-weight: theme('fontWeight.normal');
        font-size: theme('fontSize.base');
        line-height: 1.5;
        color: theme('colors.primary-800');
        border: theme('borderWidth.DEFAULT') solid theme('colors.gray-200');
        border-radius: theme('borderRadius.DEFAULT');
        background-position: right theme('spacing.4') center;
        background-size: theme('spacing.6');
        background-repeat: no-repeat;
        background-color: theme('colors.white');
    }

    .text-field[disabled],
    .text-field[readonly] {
        opacity: 0.5;
        pointer-events: none;
    }

    .text-field.valid {
        border-color: theme('colors.green.600');
        background-image: url("data:image/svg+xml,%0A%3Csvg fill='%2316a34a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' /%3E%3C/svg%3E");
    }

    .text-field.invalid {
        border-color: theme('colors.red.600');
        background-image: url("data:image/svg+xml,%3Csvg fill='%23dc2626' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z' /%3E%3C/svg%3E");
    }
</style>
