export enum KeyType {
    Email = 'email',
}

export default {
    email: {
        201: 'Thank you for your email!',
        208: 'We already have your email',
    },
}
