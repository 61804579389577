import { load } from 'recaptcha-v3'

const SITE_KEY = import.meta.env.VITE_CAPTCHA_SITE_KEY as string

export const getCaptchaToken = async () => {
    if (!SITE_KEY) {
        return ''
    }

    const captcha = await load(SITE_KEY, { autoHideBadge: true })
    const token = await captcha.execute('submit')
    return token
}
