import errors from '$lib/constants/errors'
import messages from '$lib/constants/messages'

export const getTranslatedErrorMessage = (message: string) => {
    const translatedMessage = errors[message as keyof typeof errors]
    if (translatedMessage) {
        return translatedMessage
    }

    return ''
}

export const getSuccessMessageByKeyAndStatusCode = (
    key: string,
    code: number
) => {
    const messagesBykey = messages[key as keyof typeof messages]
    const translatedMessage = messagesBykey[code as keyof typeof messagesBykey]
    return translatedMessage
}

export const getCorrectTextForCategory = (category: string) => {
    switch (category) {
        case 'Analytics and monitoring':
            return 'We have you covered. Take a look at our ready-made analytics tools.'
        case 'CMS':
            return 'We got you covered. content management systems'
        case 'Database server':
            return 'Look at our ready-made database servers.'
        case 'Database tool':
            return 'Never mind, we have other pre-configured database tools.'
        case 'Messaging':
            return 'Never mind, we have other ready-made message brokers etc.'
        case 'E-commerce':
            return 'We have got you covered. Check out our pre-configured e‑commerce solutions.'
        case 'Run-time enviroment':
            return 'We have got you covered. Pay attaention to our ready-mase run-time enviroments.'
        case 'Web server':
            return 'No problem, we have other web servers available.'
        default:
            return ''
    }
}
